var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.promoHighlight.length > 0 ? _c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: _vm.chakra,
      expression: "chakra"
    }],
    attrs: {
      "max-width": "900px",
      "mx": "auto",
      "px": ['0', '1rem'],
      "mt": ['1rem', '0']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['18px', '20px'],
      "font-weight": "500",
      "color": "#111",
      "mb": ['8px', '0px'],
      "text-align": "left"
    }
  }, [_vm._v(" Promo Berlangsung ")]), _c('VueSlickCarousel', _vm._b({
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '.slick-slide': {
          padding: '10px 10px',
          '@media(max-width: 767px)': {
            padding: '0 0 0 10px'
          }
        }
      },
      expression: "{\n      '.slick-slide': {\n        padding: '10px 10px',\n        '@media(max-width: 767px)': {\n          padding: '0 0 0 10px',\n        },\n      },\n    }"
    }]
  }, 'VueSlickCarousel', _vm.settings, false), _vm._l(_vm.promoHighlight, function (item, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "bg-color": "white",
        "border-radius": "12px",
        "overflow": "hidden",
        "as": "a",
        "box-shadow": ['0px 2px 2px rgba(17, 17, 17, 0.2)', '0px 4px 12px rgba(0, 0, 0, 0.2)'],
        "max-w": ['150px', '100%']
      },
      on: {
        "click": function click($event) {
          return _vm.handleClick(item);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": item.image ? item.image : 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/dietela_logo.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1675826444039',
        "alt": "dietela",
        "w": "100%"
      }
    }), _c('c-text', {
      attrs: {
        "p": ['8px 16px', '8px 16px'],
        "font-size": "['14px', '18px']",
        "font-weight": "500",
        "color": "#111",
        "text-transform": "capitalize",
        "is-truncated": true
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1);
  }), 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }