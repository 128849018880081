<template>
  <c-flex
    flex-direction="column"
    gap="8px"
    :padding="['10px', '12px']"
    :border-radius="['8px', '0']"
    background-color="rgba(0, 140, 129, 0.20)"
  >
    <c-flex
      gap="16px"
    >
      <c-box>
        <c-image
          v-if="!!productPhotoSrc"
          :src="productPhotoSrc"
          :max-width="['70px', '116px']"
          border-radius="12px"
        />
      </c-box>
      <c-box>
        <c-text
          font-family="Roboto"
          :font-size="['18px', '28px']"
          :font-weight="[500, 700]"
          :mb="['0.5rem', '0.75rem']"
          color="primary.400"
        >
          {{ productName }}
        </c-text>
        <c-box
          v-if="isEnterprise"
          class="for-enterprise"
          mb="1rem"
          text-align="justify"
          v-html="productDescription"
        />
        <c-text
          v-else
          font-family="Roboto"
          :font-size="['14px', '16px']"
          :mb="['0.5rem', '1rem']"
          text-align="justify"
        >
          {{ productDescription }}
        </c-text>
        <c-box
          :display="['none', 'block']"
          font-family="Roboto"
          :font-size="['12px', '16px']"
        >
          Masih bingung terkait {{ productName }}?
          <c-box
            v-if="!isEnterprise"
            as="router-link"
            d="inline-flex"
            align-items="center"
            color="#008C81"
            :to="{
              name: 'client.program.detail',
              params: {
                id: productSlug ? productSlug : productId,
              },
            }"
          >
            Pelajari Lebih Lanjut
            <c-image
              d="inline"
              :src="require('@/assets/icons/icon-info.svg')"
              :w="['12px', '16px']"
              :h="['12px', '16px']"
              ml="4px"
            />
          </c-box>
        </c-box>
        <c-button
          v-if="isEnterprise"
          v-chakra="{
            ':hover': {
              backgroundColor: '#005A64 !important',
            },
          }"
          :border-radius="['24px', '32px']"
          :px="['32px', '48px']"
          :py="['8px', '16px']"
          background-color="#008C81"
          color="#FFFFFF"
          :font-size="['14px', '18px']"
          font-weight="500"
          font-family="Roboto"
          as="router-link"
          :to="{ name: 'client.enterpriseService' }"
        >
          Pelajari Lebih Lanjut
        </c-button>
      </c-box>
    </c-flex>
    <c-box
      :display="['flex', 'none']"
      font-family="Roboto"
      :font-size="['12px', '16px']"
      justify-content="space-between"
    >
      Masih bingung terkait {{ productName }}?
      <c-box
        v-if="!isEnterprise"
        margin-left="auto"
        as="router-link"
        d="inline-flex"
        align-items="center"
        color="#008C81"
        :to="{
          name: 'client.program.detail',
          params: {
            id: productSlug ? productSlug : productId,
          },
        }"
      >
        Pelajari Lebih Lanjut
        <c-image
          d="inline"
          :src="require('@/assets/icons/icon-info.svg')"
          :w="['12px', '16px']"
          :h="['12px', '16px']"
          ml="4px"
        />
      </c-box>
    </c-box>
  </c-flex>
</template>

<script>
import { CBox, CFlex, CImage, CButton, CText } from '@chakra-ui/vue'

export default {
  name: 'CardSimpleDetailProgram',
  components: {
    CBox,
    CFlex,
    CImage,
    CButton,
    CText,
  },
  props: {
    productPhotoSrc: {
      required: true,
      type: String,
      default: '',
    },
    productName: {
      required: true,
      type: String,
      default: '',
    },
    productDescription: {
      required: true,
      type: String,
      default: '',
    },
    productSlug: {
      required: true,
      type: String,
      default: '',
    },
    productId: {
      required: true,
      type: String,
      default: '',
    },
    isEnterprise: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
