<template>
  <c-box
    w="100%"
    max-width="1270px"
    mx="auto"
    :my="['0', '16px']"
    h="auto"
    bg="#FFF"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['1rem', '1.5rem']"
    :min-height="['unset', '74vh']"
  >
    <c-box
      :padding="['0', '1rem']"
      :max-width="['auto', '900px']"
      margin="auto"
    >
      <c-text
        font-family="Roboto"
        :font-size="['16px', '28px']"
        :font-weight="['600', '700']"
        text-align="center"
        :mb="['1rem', '1.5rem']"
      >
        Pilih berdasarkan Keinginanmu
      </c-text>

      <c-grid
        v-if="productCategoryFiltered.length > 0"
        :w="['100%', '80%']"
        :template-columns="`repeat(${productCategoryFiltered.length > 4 ? 4 : productCategoryFiltered.length}, 1fr)`"
        mx="auto"
        :gap="['16px', '0']"
        :align-items="['flex-start', 'center']"
      >
        <c-button
          v-for="(menu, index) in productCategoryFiltered"
          :key="index"
          v-chakra="{
            ':hover': {
              color: '#FFFFFF',
              backgroundColor: '#005A64',
              transitionDuration: '300ms',
            },
            '&.active, &:focus, &:active': {
              color: '#FFFFFF',
              backgroundColor: '#008C81',
              width: ['100%', '220px'],
              height: ['80px','180px'],
              flexDirection: 'column-reverse',
            },
          }"
          :class="{ active: menu.id === routeQuery.categoryId }"
          d="flex"
          :height="['67px', '160px']"
          :width="['100%', '160px']"
          mx="auto"
          :flex-direction="['column-reverse', 'column']"
          jusity-content="center"
          align-items="center"
          border-radius="12px"
          :p="['5px', '20px']"
          :box-shadow="['0px 2px 2px 0px #008C8126', '0px 4px 8px rgba(0, 0, 0, 0.2)']"
          cursor="pointer"
          :color="['#111111', '#008C81']"
          background-color="#FFFFFF"
          white-space="pre-warp"
          :gap="['8px', '16px']"
          @click="handleChangeCategory(menu)"
        >
          <c-text
            :font-size="['10px', '16px']"
            font-family="Roboto"
          >
            {{ menu.name }}
          </c-text>
          <c-image
            :src="menu.icon"
            :w="['50px', '120px']"
            :border-radius="['4px','12px']"
          />
        </c-button>
      </c-grid>

      <c-box
        v-show="!isLoadingProducts"
        margin="16px 0"
      >
        <CardSimpleDetailProgram
          :product-slug="product?.slug"
          :is-enterprise="isEnterprise"
          :product-description="product?.overview"
          :product-name="product?.name"
          :product-id="product?.id"
          :product-photo-src="product?.photoUrl"
        />
      </c-box>

      <c-box
        v-show="!isLoadingProducts && !!$route.query?.product_id"
        height="100%"
      >
        <TableProductComparation
          :product-id="$route.query?.product_id || ''"
        >
          <template #button="{ productService }">
            <BaseButton
              v-chakra="{
                color: 'primary.400',
                fontWeight: ['400', '500'],
                fontSize: ['12px', '18px']
              }"
              size="small"
              variant="contained"
              w="100%"
              border-radius="1000px"
              color="secondary"
              :is-loading="isLoading.buyNow"
              @click="handleCheckOrder(productService|| {})"
            >
              Beli Sekarang
            </BaseButton>
          </template>
        </TableProductComparation>
      </c-box>
    </c-box>

    <Promos :chakra="{ d: ['none', 'block'] }" />

    <!--  MODAL -->
    <ModalInfoDifferentProgram
      :is-open="isOpenModalDifferentProgram"
      @close="isOpenModalDifferentProgram = false"
      @handle-confirm="handleNext(selectedItem)"
    />
  </c-box>
</template>

<script>
import iconCircleClose from '@/assets/icons/icon-circle-close.svg'
import iconCircleCheck from '@/assets/icons/icon-circle-check.svg'
// import Stepper from '@/components/elements/stepper.vue'
import BaseButton from '@/components/elements/base-button.vue'
import Promos from '@/views/pricing/promos.vue'
import { mapActions, mapGetters } from 'vuex'
import { reqCompareProduct } from '@/requests/dietela-api/client/compare-product'
import ModalInfoDifferentProgram from '@/components/widgets/modal-info-different-program.vue'
import CardSimpleDetailProgram from '@/components/card-simple-detail-program.vue'
import TableProductComparation from '@/components/table-product-comparation.vue'

export default {
  name: 'BuyProgram',
  components: {
    BaseButton,
    TableProductComparation,
    CardSimpleDetailProgram,
    ModalInfoDifferentProgram,
    // Stepper,
    // BaseButton,
    Promos,
  },
  data() {
    return {
      routeQuery: {
        categoryId: this.$route.query?.category_id,
        productId: this.$route.query?.product_id,
        productServiceId: this.$route.query?.product_service_id,
        duration: this.$route.query?.duration,
      },
      isLoadingProducts: false,
      durations: [],
      selectedDuration: null,
      programGrouping: [],
      isEnterprise: false,
      iconCircleClose,
      iconCircleCheck,
      isOpenModalDifferentProgram: false,
      selectedItem: {},
      isSubmittingCheckOrder: false,
      isLoading: {
        buyNow: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      productCategory: 'general/productCategory',
      isAuthenticated: 'auth/isAuthenticated',
      products: 'clients/productsByName',
      product: 'clients/product',
      userType: 'userType/userType',
    }),
    productCategoryFiltered() {
      return this.productCategory.filter((it) => it.name?.toLowerCase() != 'for enterprise')
    },
    isDisabled() {
      if (!this.product) return true
      return this.product?.productServices?.some((it) => it.id == this.routeQuery.productServiceId) ? false : true
    },
  },
  watch: {
    productCategory: {
      immediate: true,
      handler(val) {
        const hasCategoryId = !!this.routeQuery.categoryId
        const hasListProductCategory = val.length >= 1
        if (!hasListProductCategory || hasCategoryId) return

        const categoryClinicare = val.find((it) => it.name?.toLowerCase() == 'clinicare')
        if (categoryClinicare) {
          this.routeQuery.categoryId = categoryClinicare?.id
          return
        }

        this.routeQuery.categoryId = val[0]?.id
      },
    },
    routeQuery: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal?.categoryId) {
          this.$router.replace({
            query: {
              category_id: newVal?.categoryId,
              product_id: newVal?.productId,
              product_service_id: newVal?.productServiceId,
              duration: newVal?.duration,
            },
            hash: '#program',
          }).catch(() => {})
        }
      },
    },
    product: {
      handler(val) {
        if (val) {
          if (val?.name?.toLowerCase()?.includes('enterprise')) {
            this.isEnterprise = true
            this.durations = ['for enterprise']
            this.selectedDuration = this.durations[0]
            this.routeQuery.duration = this.durations[0]
            this.programGrouping = []
            return
          }
          this.isEnterprise = false
          this.durations = [...new Set(val.productServices.map(it => it.duration))]?.sort((a, b) => a-b)
          if (!this.selectedDuration || this.durations.some((it) => it == this.selectedDuration) == false) {
            this.selectedDuration = this.durations[0]
            this.routeQuery.duration = this.durations[0]
          }

          this.programGrouping = val.productServices.reduce((group, product) => {
            const { duration } = product
            group[duration] = group[duration] ?? []
            group[duration].push(product)
            return group
          }, {})
        }
      },
    },
  },
  async mounted() {
    if (this.productCategory.length == 0) {
      await this.loadProductCategory()
    }
    this.loadProducts()
    this.selectedDuration = this.routeQuery.duration
    this.triggerUpdateUserType()
  },
  methods: {
    ...mapActions({
      loadProductCategory: 'general/loadProductCategory',
      listProducts: 'clients/getProductByName',
      detailProduct: 'clients/getProductById',
      triggerUpdateUserType: 'userType/triggerUpdateUserType',
    }),
    async loadProducts() {
      this.isLoadingProducts = true
      this.durations = []
      this.programGrouping = []
      if (this.products?.length == 0 || this.products[0]?.id != this.routeQuery.productId) {
        await this.listProducts(this.routeQuery.categoryId)
        this.routeQuery.productId = this.products[0]?.id
      }
      if (this.products?.length > 0) {
        await this.detailProduct(this.products[0].id)
      }
      this.isLoadingProducts = false
    },
    handleChangeCategory(category) {
      if (this.routeQuery.categoryId != category.id) {
        this.routeQuery.categoryId = category.id
        this.routeQuery.productId = null
        this.routeQuery.productServiceId = null
        this.routeQuery.duration = null
        this.selectedDuration = null
        this.loadProducts()
      }
    },
    handleNext(item) {
      if (this.isAuthenticated) {
        // POSTHOG capture `plan purchased`
        const selectedProductService = this.product?.productServices?.find((it) => it?.id === item?.id)
        const selectedProgram = {
          id: this.product.id,
          name: this.product.name,
          productService: selectedProductService,
        }
        this.$posthog.capture('plan purchased', {
          userType: this.userType ?? 'not-registered',
          program: selectedProgram,
        })
      }

      const routerName = this.isAuthenticated ? 'client.buy.step-3' : 'client.buy.step-2'
      this.$router.push({
        name: routerName,
        query: {
          ...this.$route.query,
          product_service_id: item.id,
          product_id: item.productId,
        },
      })
    },
    async handleCheckOrder(item) {
      this.isLoading.buyNow = true
      if (!this.isAuthenticated) {
        this.handleNext(item)
        this.isLoading.buyNow = false
        return
      }
      this.isSubmittingCheckOrder = true
      const showPopupInfo = await reqCompareProduct(
        this.$store.getters.axios,
        item?.id,
      ).then((res) => res.data?.data?.isPopUp).finally(() => {
        this.isSubmittingCheckOrder = false
      })
      if (showPopupInfo) {
        this.selectedItem = item
        this.isOpenModalDifferentProgram = true
        this.isLoading.buyNow = false
        return
      }
      window.fbq('track', 'BuyNow')
      this.handleNext(item)
      this.isLoading.buyNow = false
    },
  },
}
</script>

<style scoped>
.icon-list-recommendation {
  width: 15px;
  height: 15px;
  flex-shrink: 0;
}

::v-deep .for-enterprise img {
  margin: 0 auto;
  width: 200px;
}

@media (min-width: 768px) {
  .icon-list-recommendation {
    width: 20px;
    height: 20px;
  }

  ::v-deep .for-enterprise img {
    width: 250px;
  }
}
</style>