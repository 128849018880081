<template>
  <c-box 
    v-if="promoHighlight.length > 0"
    v-chakra="chakra"
    max-width="900px" 
    mx="auto" 
    :px="['0', '1rem']"
    :mt="['1rem', '0']"
  >
    <c-text
      :font-size="['18px', '20px']" 
      font-weight="500" 
      color="#111" 
      :mb="['8px', '0px']"
      text-align="left"
    >
      Promo Berlangsung
    </c-text>
    <VueSlickCarousel
      v-chakra="{
        '.slick-slide': {
          padding: '10px 10px',
          '@media(max-width: 767px)': {
            padding: '0 0 0 10px',
          },
        },
      }"
      v-bind="settings"
    >
      <c-box
        v-for="(item, index) in promoHighlight"
        :key="index"
        bg-color="white"
        border-radius="12px"
        overflow="hidden"
        as="a"
        :box-shadow="['0px 2px 2px rgba(17, 17, 17, 0.2)', '0px 4px 12px rgba(0, 0, 0, 0.2)']"
        :max-w="['150px', '100%']"
        @click="handleClick(item)"
      >
        <c-image
          :src="
            item.image
              ? item.image
              : 'https://ik.imagekit.io/dietela/pwa_webp/landing_page/dietela_logo.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1675826444039'
          "
          alt="dietela"
          w="100%"
        />
        <c-text
          :p="['8px 16px', '8px 16px']"
          font-size="['14px', '18px']"
          font-weight="500"
          color="#111"
          text-transform="capitalize"
          :is-truncated="true"
        >
          {{ item.title }}
        </c-text>
      </c-box>
    </VueSlickCarousel>
  </c-box>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PromoBerlangsung',
  components: {
    VueSlickCarousel,
  },
  props: {
    chakra: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      settings: {
        arrows: false,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        draggable: false,
        swipeToSlide: false,
        touchMove: false,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              draggable: true,
              swipeToSlide: true,
              touchMove: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              variableWidth: true,
            },
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      promoHighlight: 'general/promoHighlight',
    }),
  },
  mounted() {
    if (this.promoHighlight.length == 0) {
      this.loadPromoHighlight()
    }
  },
  methods: {
    ...mapActions({
      loadPromoHighlight: 'general/getPromoHighlight',
    }),
    handleClick(item) {
      const isExternalLink = item.link && item.link.startsWith('http')
      if (isExternalLink && !item.buttonText) {
        window.open(item.link, '_blank')
        return
      }

      this.$router.push({
        name: 'client.promo',
        params: { id: item.id },
      })
    },
  },
}
</script>
