var render = function () {
  var _vm$product, _vm$product2, _vm$product3, _vm$product4, _vm$product5, _vm$$route$query, _vm$$route$query2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "1270px",
      "mx": "auto",
      "my": ['0', '16px'],
      "h": "auto",
      "bg": "#FFF",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['1rem', '1.5rem'],
      "min-height": ['unset', '74vh']
    }
  }, [_c('c-box', {
    attrs: {
      "padding": ['0', '1rem'],
      "max-width": ['auto', '900px'],
      "margin": "auto"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['16px', '28px'],
      "font-weight": ['600', '700'],
      "text-align": "center",
      "mb": ['1rem', '1.5rem']
    }
  }, [_vm._v(" Pilih berdasarkan Keinginanmu ")]), _vm.productCategoryFiltered.length > 0 ? _c('c-grid', {
    attrs: {
      "w": ['100%', '80%'],
      "template-columns": "repeat(".concat(_vm.productCategoryFiltered.length > 4 ? 4 : _vm.productCategoryFiltered.length, ", 1fr)"),
      "mx": "auto",
      "gap": ['16px', '0'],
      "align-items": ['flex-start', 'center']
    }
  }, _vm._l(_vm.productCategoryFiltered, function (menu, index) {
    return _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            color: '#FFFFFF',
            backgroundColor: '#005A64',
            transitionDuration: '300ms'
          },
          '&.active, &:focus, &:active': {
            color: '#FFFFFF',
            backgroundColor: '#008C81',
            width: ['100%', '220px'],
            height: ['80px', '180px'],
            flexDirection: 'column-reverse'
          }
        },
        expression: "{\n          ':hover': {\n            color: '#FFFFFF',\n            backgroundColor: '#005A64',\n            transitionDuration: '300ms',\n          },\n          '&.active, &:focus, &:active': {\n            color: '#FFFFFF',\n            backgroundColor: '#008C81',\n            width: ['100%', '220px'],\n            height: ['80px','180px'],\n            flexDirection: 'column-reverse',\n          },\n        }"
      }],
      key: index,
      class: {
        active: menu.id === _vm.routeQuery.categoryId
      },
      attrs: {
        "d": "flex",
        "height": ['67px', '160px'],
        "width": ['100%', '160px'],
        "mx": "auto",
        "flex-direction": ['column-reverse', 'column'],
        "jusity-content": "center",
        "align-items": "center",
        "border-radius": "12px",
        "p": ['5px', '20px'],
        "box-shadow": ['0px 2px 2px 0px #008C8126', '0px 4px 8px rgba(0, 0, 0, 0.2)'],
        "cursor": "pointer",
        "color": ['#111111', '#008C81'],
        "background-color": "#FFFFFF",
        "white-space": "pre-warp",
        "gap": ['8px', '16px']
      },
      on: {
        "click": function click($event) {
          return _vm.handleChangeCategory(menu);
        }
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['10px', '16px'],
        "font-family": "Roboto"
      }
    }, [_vm._v(" " + _vm._s(menu.name) + " ")]), _c('c-image', {
      attrs: {
        "src": menu.icon,
        "w": ['50px', '120px'],
        "border-radius": ['4px', '12px']
      }
    })], 1);
  }), 1) : _vm._e(), _c('c-box', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isLoadingProducts,
      expression: "!isLoadingProducts"
    }],
    attrs: {
      "margin": "16px 0"
    }
  }, [_c('CardSimpleDetailProgram', {
    attrs: {
      "product-slug": (_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.slug,
      "is-enterprise": _vm.isEnterprise,
      "product-description": (_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : _vm$product2.overview,
      "product-name": (_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : _vm$product3.name,
      "product-id": (_vm$product4 = _vm.product) === null || _vm$product4 === void 0 ? void 0 : _vm$product4.id,
      "product-photo-src": (_vm$product5 = _vm.product) === null || _vm$product5 === void 0 ? void 0 : _vm$product5.photoUrl
    }
  })], 1), _c('c-box', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isLoadingProducts && !!((_vm$$route$query = _vm.$route.query) !== null && _vm$$route$query !== void 0 && _vm$$route$query.product_id),
      expression: "!isLoadingProducts && !!$route.query?.product_id"
    }],
    attrs: {
      "height": "100%"
    }
  }, [_c('TableProductComparation', {
    attrs: {
      "product-id": ((_vm$$route$query2 = _vm.$route.query) === null || _vm$$route$query2 === void 0 ? void 0 : _vm$$route$query2.product_id) || ''
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn(_ref) {
        var productService = _ref.productService;
        return [_c('BaseButton', {
          directives: [{
            name: "chakra",
            rawName: "v-chakra",
            value: {
              color: 'primary.400',
              fontWeight: ['400', '500'],
              fontSize: ['12px', '18px']
            },
            expression: "{\n              color: 'primary.400',\n              fontWeight: ['400', '500'],\n              fontSize: ['12px', '18px']\n            }"
          }],
          attrs: {
            "size": "small",
            "variant": "contained",
            "w": "100%",
            "border-radius": "1000px",
            "color": "secondary",
            "is-loading": _vm.isLoading.buyNow
          },
          on: {
            "click": function click($event) {
              return _vm.handleCheckOrder(productService || {});
            }
          }
        }, [_vm._v(" Beli Sekarang ")])];
      }
    }])
  })], 1)], 1), _c('Promos', {
    attrs: {
      "chakra": {
        d: ['none', 'block']
      }
    }
  }), _c('ModalInfoDifferentProgram', {
    attrs: {
      "is-open": _vm.isOpenModalDifferentProgram
    },
    on: {
      "close": function close($event) {
        _vm.isOpenModalDifferentProgram = false;
      },
      "handle-confirm": function handleConfirm($event) {
        return _vm.handleNext(_vm.selectedItem);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }