var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "gap": "8px",
      "padding": ['10px', '12px'],
      "border-radius": ['8px', '0'],
      "background-color": "rgba(0, 140, 129, 0.20)"
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "16px"
    }
  }, [_c('c-box', [!!_vm.productPhotoSrc ? _c('c-image', {
    attrs: {
      "src": _vm.productPhotoSrc,
      "max-width": ['70px', '116px'],
      "border-radius": "12px"
    }
  }) : _vm._e()], 1), _c('c-box', [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['18px', '28px'],
      "font-weight": [500, 700],
      "mb": ['0.5rem', '0.75rem'],
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s(_vm.productName) + " ")]), _vm.isEnterprise ? _c('c-box', {
    staticClass: "for-enterprise",
    attrs: {
      "mb": "1rem",
      "text-align": "justify"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.productDescription)
    }
  }) : _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "mb": ['0.5rem', '1rem'],
      "text-align": "justify"
    }
  }, [_vm._v(" " + _vm._s(_vm.productDescription) + " ")]), _c('c-box', {
    attrs: {
      "display": ['none', 'block'],
      "font-family": "Roboto",
      "font-size": ['12px', '16px']
    }
  }, [_vm._v(" Masih bingung terkait " + _vm._s(_vm.productName) + "? "), !_vm.isEnterprise ? _c('c-box', {
    attrs: {
      "as": "router-link",
      "d": "inline-flex",
      "align-items": "center",
      "color": "#008C81",
      "to": {
        name: 'client.program.detail',
        params: {
          id: _vm.productSlug ? _vm.productSlug : _vm.productId
        }
      }
    }
  }, [_vm._v(" Pelajari Lebih Lanjut "), _c('c-image', {
    attrs: {
      "d": "inline",
      "src": require('@/assets/icons/icon-info.svg'),
      "w": ['12px', '16px'],
      "h": ['12px', '16px'],
      "ml": "4px"
    }
  })], 1) : _vm._e()], 1), _vm.isEnterprise ? _c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          backgroundColor: '#005A64 !important'
        }
      },
      expression: "{\n          ':hover': {\n            backgroundColor: '#005A64 !important',\n          },\n        }"
    }],
    attrs: {
      "border-radius": ['24px', '32px'],
      "px": ['32px', '48px'],
      "py": ['8px', '16px'],
      "background-color": "#008C81",
      "color": "#FFFFFF",
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "font-family": "Roboto",
      "as": "router-link",
      "to": {
        name: 'client.enterpriseService'
      }
    }
  }, [_vm._v(" Pelajari Lebih Lanjut ")]) : _vm._e()], 1)], 1), _c('c-box', {
    attrs: {
      "display": ['flex', 'none'],
      "font-family": "Roboto",
      "font-size": ['12px', '16px'],
      "justify-content": "space-between"
    }
  }, [_vm._v(" Masih bingung terkait " + _vm._s(_vm.productName) + "? "), !_vm.isEnterprise ? _c('c-box', {
    attrs: {
      "margin-left": "auto",
      "as": "router-link",
      "d": "inline-flex",
      "align-items": "center",
      "color": "#008C81",
      "to": {
        name: 'client.program.detail',
        params: {
          id: _vm.productSlug ? _vm.productSlug : _vm.productId
        }
      }
    }
  }, [_vm._v(" Pelajari Lebih Lanjut "), _c('c-image', {
    attrs: {
      "d": "inline",
      "src": require('@/assets/icons/icon-info.svg'),
      "w": ['12px', '16px'],
      "h": ['12px', '16px'],
      "ml": "4px"
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }